.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

button {
  background-color: #1e2326;
  border: none;
  color: #bbc3c5;
  font-size: medium;
  font-family:Consolas, 'Courier New', monospace;
  padding-left: 5%;
  padding-top: 2%;
  cursor: pointer
}

table, th, td {
  border: 1px solid #bbc3c5;
  border-collapse: collapse;
  border-style: dotted;
}

.md {
  color: #bbc3c5;
  font-family:'Helvetica Neue', sans-serif;
}

a {
  color: #bbc3c5;
}


@font-face {
  font-family: 'JetbrainsMono';
  src: local('JetBrainsMono'), url(../assets/fonts/JetBrainsMono-Regular.ttf) format('truetype');
}