.editor-background {
  background-color: #23272e;
}

.editor-pos {
  position: fixed;
  height: 100%;
  width: 78%;
  margin-left: 22%;
  margin-top: 1.35%;
  padding: 1%;
  top: 0px;
  left: 0px;
  overflow-y: scroll;
}